//imports
import React from "react";
import style from "./css/SettingsSpash.module.css";

function SettingsSpash() {
  return(
    <>
     <div id={style.bg}>
       <div id={style.profile}>
         <div id={style.dp}>
         </div>
         <div className={style.gap}></div>
         <div>
           <div className={style.text}></div>
           <div className={style.smallText}></div>
         </div>
       </div>
       <div className={style.text}></div>
       <div className={style.options}></div>
       <div className={style.options}></div>
       <div className={style.options}></div>
       <div className={style.text}></div>
       <div className={style.options}></div>
       <div className={style.options}></div>
       <div className={style.options}></div>
     </div>
    </>
    );
};
export default SettingsSpash;