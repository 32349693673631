//imports
import React, { useState } from "react";
import Confirm from "./Confirm.js"; //components

function AppInDev(){
  const eZexaMessage = `Dear Users,

We are excited to announce that the E-Zexa App is currently under development. Our team is working diligently to ensure it meets the highest standards of quality, usability, and performance.

Once the app is fully developed and tested, it will be officially launched and made available to the public. We appreciate your patience and support during this process and look forward to bringing you an amazing platform soon!

Stay tuned for updates, and thank you for being a part of our journey.

Warm regards,  
E-Zexa Team`;
  const [confirm, setConfirm] = useState(true);
  const [confirmData, setConfirmData] = useState({title: "Notice",
      msg: eZexaMessage,});
    
   //
  ///
  return(
    <>
      <Confirm 
      confirm={setConfirm} 
      title={confirmData.title}
      msg={confirmData.msg}/>
    </>
    )
};

export default AppInDev;