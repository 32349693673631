// imports
import style from "./css/Details.module.css";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer.js"; //components

function PrivacyPolicy(props) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={style.bg}>
        <div className={style.ppcontainer}>
        <div className={style.header}>
          <p>
            <i className="fa-solid fa-chevron-left" onClick={goBack}></i> Privacy Policy
          </p>
        </div>
          <div className={style.ppcontent}>
            <h4>Privacy Policy for E-Zexa App</h4>
            <p><strong>Effective Date:</strong> 17 November 2024</p>

            <p>
              At <strong>E-Zexa</strong>, we value your privacy and are committed to protecting your personal information. This privacy policy outlines how we collect, use, and safeguard your data when you use our app. By using E-Zexa, you agree to the collection and use of your information as described in this policy.
            </p>

            <h4>1. Information We Collect</h4>
            <p>We collect the following types of personal information from users:</p>
            <ul>
              <li><strong>Account Information:</strong> Email address to ensure account security.</li>
              <li><strong>Payment Information:</strong> UPI ID and transaction details to process withdrawals.</li>
              <li><strong>Usage Data:</strong> Information about how you use the app, including gameplay data and tasks completed.</li>
            </ul>

            <h4>2. How We Use Your Information</h4>
            <p>The data we collect is used for the following purposes:</p>
            <ul>
              <li>Account management, creation, and maintenance.</li>
              <li>To process earnings, withdrawals, and related activities.</li>
              <li>To enhance user experience, personalize content, and improve app functionality.</li>
              <li>For security to protect your account and app access.</li>
            </ul>

            <h4>3. Data Sharing</h4>
            <p>We do not share your personal information with third parties except for the following:</p>
            <ul>
              <li><strong>Payment Processing:</strong> Trusted payment processors for withdrawals.</li>
              <li><strong>App Improvements:</strong> Third-party analytics services that do not have access to personally identifiable information.</li>
            </ul>

            <h4>4. User Consent</h4>
            <p>By using the E-Zexa app, you consent to the collection and use of your information as described in this privacy policy. If you do not agree with the terms, please refrain from using the app.</p>

            <h4>5. Data Security</h4>
            <p>We implement security measures to protect your personal information, including encryption and secure storage. However, no method of transmission is 100% secure, and we cannot guarantee absolute security.</p>

            <h4>6. Your Rights</h4>
            <p>You have the following rights regarding your personal data:</p>
            <ul>
              <li>Access: Request a copy of the data we have collected.</li>
              <li>Correction: Update your personal information.</li>
              <li>Deletion: Request deletion of your data and account.</li>
            </ul>

            <h4>7. Changes to This Privacy Policy</h4>
            <p>We may update this privacy policy periodically. When changes are made, the updated policy will be posted with a new effective date.</p>

            <h4>8. Contact Us</h4>
            <p>If you have any questions, please contact us at:</p>
            <p><strong>Email:</strong> ezexainc@gmail.com</p>
            <p><strong>Support:</strong> @ezexa_official (Instagram)</p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;