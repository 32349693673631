// imports 
import React, { useEffect, useState, useRef } from "react";
import style from "./css/DailyRewards.module.css";
import { auth, db } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { onValue, get, set, ref as refDb } from "firebase/database";
import Toast from "./Toast.js"; // components
import Loading from "./Loading.js" // components 

function DailyRewards() {
  //
  // variables and react hooks
  //
  const rs = "₹";
  const newDate = new Date();
  const date = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getYear()}`;
  const [user, setUser] = useState(null);
  const rewardBtn1 = useRef(null);
  const rewardBtn2 = useRef(null);
  const rewardBtn3 = useRef(null);
  const [task1Track, setTast1Track] = useState(0);
  const [task2Track, setTast2Track] = useState(0);
  const [toast, setToast] = useState(false);
  const [toastData, setToastData] =useState({});
  const [loading, setLoading] = useState(false);
  //
  // functions
  //
  const toastShow = () => {
    setToast(!toast);
    setTimeout(function() {
      setToast(false);
    }, 5000);
  };
  //
  //
  useEffect(() => {
    const authListener = onAuthStateChanged(auth, async (detectedUser) => {
      if (detectedUser) {
        try{
          //
        setUser(detectedUser);
        const rewardsRef  = refDb(db, "userRewards/" + detectedUser.uid);
        const snapshot = await get(rewardsRef);
        const rewards = snapshot.val();
        //
        if(snapshot.exists()){
          //check reward1 
           if(rewards.task1){
             if(rewards.task1.date == date){
               setTast1Track(rewards.task1.track);
             }else{
              setTast1Track(0);
             };
             //
            if(rewards.task1.claimed == date){
              rewardBtn1.current.style.backgroundColor = "silver";
              setTast1Track(20);
              rewardBtn1.current.innerText = "claimed";
            }else if(rewards.task1.track >= 20){
              if(rewards.task1.date == date){
               setTast1Track(20);
               rewardBtn1.current.style.backgroundColor = "gold";
              };
            };
            //
          };
          //check reward2 
          if(rewards.task2){
             if(rewards.task2.date == date){
               setTast2Track(rewards.task2.track);
             }else{
              setTast2Track(0);
             };
             //
            if(rewards.task2.claimed == date){
              rewardBtn2.current.style.backgroundColor = "silver";
              setTast2Track(20);
              rewardBtn2.current.innerText = "claimed";
            }else if(rewards.task2.track >= 10){
              if(rewards.task2.date == date){
               setTast2Track(10);
               rewardBtn2.current.style.backgroundColor = "gold";
              };
            };
            //
          };
          //check tast3
          if(rewards.task3 == date){
            rewardBtn3.current.style.backgroundColor = "silver";
            rewardBtn3.current.innerText = "claimed";
          }else{
            rewardBtn3.current.style.backgroundColor = "gold";
          };
        };
        //
      }catch(e){
        
      };
      //
      };
    });
    return () => authListener();
  }, []);
  //
  //
  //task1 handle 
  const task1Handle = async () => {
    setLoading(true);
    const userRuppee = await get(refDb(db, "ruppee/" + user.uid));
    const rewardsRef  = refDb(db, "userRewards/" + user.uid + "/task1/");
    const snapshot = await get(rewardsRef);
    const rewards = snapshot.val();
    //
       if(rewards){
        if(rewards.claimed== date){
          setToastData({
            ...rewards,
           color: "red",
           msg: "Already Claimed!",
            });
            toastShow();
        }else if(rewards.track >= 20){
          if(rewards.date == date){
          set(refDb(db, "ruppee/" + user.uid), userRuppee.val() + 5);
          set(rewardsRef, {
              claimed: date,
              track: 0,
              date: date,
          });
          setToastData({
            color: "green",
            msg: "Claimed!",
            });
            toastShow();
            rewardBtn1.current.style.backgroundColor = "silver";
            rewardBtn1.current.innerText = "claimed";
        };
        };
      };
      setLoading(false);
    };
  //tas2k2 handle 
  //
  const task2Handle = async () => {
    setLoading(true);
    const userRuppee = await get(refDb(db, "ruppee/" + user.uid));
    const rewardsRef  = refDb(db, "userRewards/" + user.uid + "/task2/");
    const snapshot = await get(rewardsRef);
    const rewards = snapshot.val();
    //
       if(rewards){
        if(rewards.claimed == date){
          setToastData({
            ...rewards,
           color: "red",
           msg: "Already Claimed!",
            });
            toastShow();
        }else if(rewards.track >= 10){
          if(rewards.date == date){
          set(refDb(db, "ruppee/" + user.uid), userRuppee.val() + 5);
          set(rewardsRef, {
              claimed: date,
              track: 0,
              date: date,
          });
          setToastData({
            color: "green",
            msg: "Claimed!",
            });
            toastShow();
            rewardBtn2.current.style.backgroundColor = "silver";
            rewardBtn2.current.innerText = "claimed";
        };
        };
      };
      setLoading(false);
    };
  //task3 handle
  //
  const task3Handle = async () => {
    setLoading(true);
    rewardBtn3.current.style.backgroundColor = "silver";
    rewardBtn3.current.innerText = "claimed";
    const rewardsRef  = refDb(db, "userRewards/" + user.uid);
    const userExp = await get(refDb(db, "userExp/" + user.uid));
    const snapshot = await get(rewardsRef);
    const rewards = snapshot.val();
    if(rewards.task3 !== date){
       await set(refDb(db, "userExp/" + user.uid), userExp.val() + 100);
       await set(rewardsRef, {
         ...rewards,
         task3: date,
         });
         setToastData({
         color: "green",
         msg: "Claimed!",
       });
       toastShow();
     }else{
       setToastData({
         color: "red",
         msg: "Already Claimed!",
       });
       toastShow();
     };
     setLoading(false);
  };
  //
  return(
    <>
    <div id={style.bg} >
      <div className={style.linear} >
      <ul>
        <li>Cash Out 20 times in SCL & {rs}5</li>
        </ul>
        <div>
        <p>20/{task1Track}</p>
        <button ref={rewardBtn1} 
                className={style.btns}
                onClick={task1Handle}>
         claim
        </button>
        </div>
      </div>
       <div className={style.linear} >
       <ul>
        <li>Win Card EW game 10 times & get {rs}5 </li>
        </ul>
        <div>
        <p>10/{task2Track}</p>
        <button ref={rewardBtn2}
                onClick={task2Handle}
                className={style.btns}>
         claim
        </button>
        </div>
      </div>
       <div className={style.linear} >
       <ul>
        <li>Open App Today and get 100 EXP</li>
        </ul>
        <div>
        <img src="/images/exp.png" id={style.exp} />
        <button onClick={task3Handle} ref={rewardBtn3} className={style.btns}>
         claim
        </button>
        </div>
      </div>
    </div>
    { toast && <Toast msg={toastData.msg} color={toastData.color} toastShow={toastShow} /> }
    { loading && <loading /> }
    </>
    );
};
export default DailyRewards;