//imports
import Recat from "react";
import style from "./css/Confirm.module.css";

function Confirm(props){
  //
  // variables and React hooks
  //
  
  //
  // functions
  //
  const okayClick = () => {
    if(props.confirmVal){
    props.confirmVal(true);
    props.confirm(false);
    };
  };
  const cancleClick = () => {
    if(props.confirmVal){
    props.confirmVal(false);
    props.confirm(false);
    };
  };
  //
  return (
    <>
      <div id={style.bg}>
        <div id={style.confirm}>
          <div id={style.header}>
            
            <p>{props.title ? props.title : 'Notice'}</p>
          </div>
          <div id={style.body}>
            <p>{props.msg ? props.msg : "Visit E-Zexa and earn without any investment, play games and complete tasks and earn!"}</p>
          </div>
          <div id={style.footer}>
            <button id={style.cancleBtn} onClick={cancleClick}>
              Cancle
            </button>
            <button id={style.okBtn} onClick={okayClick}>
              Okay
            </button>
          </div>
        </div>
      </div>
    </>
    )
};

export default Confirm;