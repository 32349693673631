import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TermsAndConditions from "./Components/TermsAndConditions.js"; //Components
import PrivacyPolicy from "./Components/PrivacyPolicy.js"; //Components
import About from "./Components/About.js" //Components
import AppInDev from "./Components/AppInDev.js";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
   <Routes>
    <Route path="/" element={ <AppInDev /> }/>
    <Route path="/TermsAndConditions" element={ <TermsAndConditions /> }/>
    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
    <Route path="/About" element={<About />} />
   </Routes>
  </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA
