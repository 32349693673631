// imports
import React from "react";
import style from "./css/Footer.module.css";
import { useNavigate } from "react-router-dom";

function Footer(){
  const navigate = useNavigate();
  const goToTAC = () => {
    navigate("/TermsAndConditions");
  };
  const goToAU = () => {
    navigate("/About");
  };
  const goToPP = () => {
    navigate("/PrivacyPolicy");
  };
  return(
  <>
    <div id={style.bg}>
    <div><p onClick={goToTAC}>Terms <span> & </span> Conditions</p>
      <p>| Copyright &copy; 2024 | E-Zexa</p></div>
      <div><p onClick={goToAU}>About us </p><p onClick={goToPP}>| Privacy Policy </p></div>
    </div>
  </>
  );
};

export default Footer;
