import React, { useEffect } from "react";

const BannerAD = () => {
  useEffect(() => {
    // Create the adOptions script
    const optionsScript = document.createElement("script");
    optionsScript.type = "text/javascript";
    optionsScript.innerHTML = `
      atOptions = {
        'key': '18b8331f266e4ce0993807bb5b851557',
        'format': 'iframe',
        'height': 600,
        'width': 160,
        'params': {}
      };
    `;

    // Create the main ad script
    const mainScript = document.createElement("script");
    mainScript.type = "text/javascript";
    mainScript.async = true;
    mainScript.src = "//www.highperformanceformat.com/18b8331f266e4ce0993807bb5b851557/invoke.js";

    // Append both scripts to the container
    const adContainer = document.getElementById("ad-container");
    adContainer.appendChild(optionsScript);
    adContainer.appendChild(mainScript);

    // Cleanup scripts when component unmounts
    return () => {
      adContainer.removeChild(optionsScript);
      adContainer.removeChild(mainScript);
    };
  }, []);

  return (
    <div id="ad-container" style={{ margin: "20px auto", textAlign: "center" }}>
      {/* Ad will render here */}
    </div>
  );
};

export default BannerAD;