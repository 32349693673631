// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDu5fxfUjwxM5b8_5uuC_0VxLyYLdfg05s",
  authDomain: "ezexa.xyz",
  databaseURL: "https://e-zexa0-default-rtdb.firebaseio.com",
  projectId: "e-zexa0",
  storageBucket: "e-zexa0.firebasestorage.app",
  messagingSenderId: "1078153862230",
  appId: "1:1078153862230:web:9cd7dd339695dcd5af2ff7",
  measurementId: "G-TB1DG3YE0C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getDatabase(app);
export {auth, provider, signInWithPopup, db};