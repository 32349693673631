// imports  
import React from "react";
import style from "./css/Details.module.css";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer.js"; //components

function About() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={style.bg}>
        {/* Header */}
        <div className={style.header}>
          <p>
            <i className="fa-solid fa-chevron-left" onClick={goBack}></i> About us
          </p>
        </div>
        {/* Body */}
        <div className={style.aboutContainer}>
          <h1>About Us</h1>
          <div>
          <img src="./images/kishan_raj_singh.jpg" />
         <strong> Kishan Raj Singh </strong>
          </div>
          <p>
            Welcome to <strong>E-Zexa</strong>, an innovative platform founded by <strong>Kishan Raj Singh</strong>, who hails from <strong>Mirzapur, Uttar Pradesh</strong>. I, Kishan Raj Singh, created this app with the mission of empowering individuals who face financial constraints. My vision was to develop a space where users can earn money without any investment by playing games and completing tasks, providing them an enjoyable and rewarding experience.
          </p>
          <p>
            <strong>E-Zexa</strong> is not just an app; it’s a free platform where opportunities and fun come together. Originally launched in <strong>2023</strong>, the app did not gain the popularity needed for its mission. However, with dedication and the support of my team, I redeveloped and reintroduced <strong>E-Zexa</strong> in <strong>November 2024</strong> to better serve users and reach our goals.
          </p>
          <p>
            As a student and developer, I’ve poured my passion into ensuring this platform is accessible, secure, and engaging. Our services are designed to help people make extra income without any financial risk. To ensure account security, users link their accounts via email verification, and payments are processed swiftly through UPI, guaranteeing a reliable user experience.
          </p>
          <p>
            <strong>E-Zexa</strong> is built by a team committed to continuous improvement and customer satisfaction. Our mission is to reach those in need, making earning opportunities accessible to everyone, and fostering a supportive community where enjoyment and financial benefits go hand in hand.
          </p>
          <p>
            Join us today, enjoy the games and tasks, and start earning with <strong>E-Zexa</strong>—a platform where your time is valued and rewarded!
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default About;